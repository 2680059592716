<script setup lang="ts">
import SmallDropdown from "~/components/Common/Dropdowns/SmallDropdown.vue";
import UserIcon from "~/components/Header/UserIcon.vue";
import AccountDropdown from "~/components/Header/AccountDropdown.vue";
import { useHeader } from "~/composables/header/useHeader";
import { useAuthStore } from "~/store/auth/auth.store";
import SvgButton from "../Common/Buttons/SvgButton.vue";

const header = useHeader();
const show_account = ref<boolean>(false);
const user_icon = ref(null);
const authStore = useAuthStore();

declare global {
  interface Window {
    Beacon: any;
  }
}

function openHelpscout() {
  window.Beacon('open');
}

const eventToggleOffDropdown = (event: FocusEvent) => {
  if (event?.relatedTarget instanceof HTMLElement) {
    if (event.relatedTarget.classList.contains("disconnect_button")) {
      return;
    }
  }
  show_account.value = false;
};
</script>

<template>
  <div class="relative">
    <nav class="header">
      <ClientOnly>
        <div class="heading_2_bold">
          <span v-if="header.pageName.value">{{ header.pageName.value }}</span>
        </div>
      </ClientOnly>
      <div class="flex gap-[16px] items-center">
        <SvgButton
          class="tooltip cursor-pointer" 
          name="tooltip"
          :style="{ border: 'none', height: 'max-content'}"
          @click="openHelpscout"
        ></SvgButton>
        <SmallDropdown></SmallDropdown>
        <UserIcon
          v-if="authStore.isLoggedIn()"
          @click="show_account = !show_account"
          @focusout="eventToggleOffDropdown"
          ref="user_icon"
        ></UserIcon>
      </div>
    </nav>
    <AccountDropdown
      v-if="authStore.isLoggedIn()"
      @close="show_account = false"
      :show_account="show_account"
    ></AccountDropdown>
  </div>
</template>

<style scoped lang="scss">
.header {
  @apply flex flex-row items-center justify-between gap-[18px] px-[32px] py-2;
  background: var(--white-main);
  border-bottom: 1px solid var(--grey-lightest);
}

.tooltip{
  color: var(--text-secondary-color);
  &:hover {
    background-color: var(--grey-light);
    color: var(--text-secondary-color);
  }
}
</style>
